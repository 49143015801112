import './index.scss';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, history } from './store';
import { ConnectedRouter } from 'connected-react-router';
import { I18nextProvider } from 'react-i18next';

import App from './components/App';
import i18n from './configs/i18n';

const container = document.getElementById('root')
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </ConnectedRouter>
  </Provider>,
);
