import {
  POST_MESSAGE_REQUEST,
  POST_MESSAGE_FAILURE,
  POST_MESSAGE_SUCCESS,
  GET_CONVERSATION_REQUEST,
  GET_CONVERSATION_FAILURE,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATIONS_REQUEST,
  GET_CONVERSATIONS_FAILURE,
  GET_CONVERSATIONS_SUCCESS
} from '../constants/actionTypes';

import agent from '../agent';
import i18next from 'i18next';
import { setToastErrorMessage } from './common';
import { getUserFullName } from '../utils/userUtils';

export const postMessageRequest = () => ({ type: POST_MESSAGE_REQUEST });

export const postMessageSuccess = payload => ({
  type: POST_MESSAGE_SUCCESS,
  payload
});
export const postMessageFailure = error => ({ type: POST_MESSAGE_FAILURE, error });

export const postMessage = (conversationId, messagePayload) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(postMessageRequest());

    try {
      const updatedMessage = await agent.MessagesService.postMessage(conversationId, messagePayload, true);
      dispatch(postMessageSuccess(updatedMessage));
      return true;
    } catch (error) {
      const errorMsg = `${i18next.t('MODAL_CHANGE_REQUEST_ERROR_PREFIX')} ${error.message}`;
      dispatch(postMessageFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Could not send Request Change message: ${JSON.stringify(error)}`));
      return false;
    }
  };
};

export const getConversationsRequest = () => ({ type: GET_CONVERSATIONS_REQUEST });

export const getConversationsSuccess = payload => ({
  type: GET_CONVERSATIONS_SUCCESS,
  payload
});
export const getConversationsFailure = error => ({ type: GET_CONVERSATIONS_FAILURE, error });

export const getConversations = isExpertUser => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(getConversationsRequest());

    try {
      const paginatedResult = await agent.TripsService.getAllTripResumes(false, true);
      const tripResumes = paginatedResult.result.listings

      const conversations = tripResumes.map(tripResume => {
        const { regularUser, expertUser, title, conversation } = tripResume;
        regularUser.name = getUserFullName(regularUser, regularUser.email);
        expertUser.name = getUserFullName(expertUser, expertUser.email);

        const conv = {
          regularUser,
          expertUser,
          title,
          _id: conversation._id,
          lastModified: new Date(conversation.lastModified || 0),
          lastSeenByExpertUser: new Date(conversation.lastSeenByExpertUser || 0),
          lastSeenByRegularUser: new Date(conversation.lastSeenByRegularUser || 0)
        };

        const lastSeenByUser = isExpertUser ? conv.lastSeenByExpertUser.getTime() : conv.lastSeenByRegularUser.getTime();
        conv.hasNewMessages = conv.lastModified.getTime() > lastSeenByUser;
        return conv;
      });

      // orders conversations based on 'lastModified' field in descending order
      const sortedConversations = conversations.sort(function (a, b) {
        const aDate = new Date(a.lastModified || 0);
        const bDate = new Date(b.lastModified || 0);

        return bDate.getTime() - aDate.getTime();
      });

      dispatch(getConversationsSuccess(sortedConversations));
      return sortedConversations;
    } catch (error) {
      const errorMsg = ` ${error.message}`;
      dispatch(getConversationsFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Could not get Conversations: ${JSON.stringify(error)}`));
      return null;
    }
  };
};

export const getConversationRequest = () => ({ type: GET_CONVERSATION_REQUEST });

export const getConversationSuccess = payload => ({
  type: GET_CONVERSATION_SUCCESS,
  payload
});
export const getConversationFailure = error => ({ type: GET_CONVERSATION_FAILURE, error });

export const getConversation = conversationId => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(getConversationRequest());

    try {
      const conversation = await agent.MessagesService.getConversation(conversationId);
      dispatch(getConversationSuccess(conversation));
      return conversation;
    } catch (error) {
      const errorMsg = `${error.message}`;
      dispatch(getConversationFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Could not get Conversation: ${JSON.stringify(error)}`));
    }
  };
};
